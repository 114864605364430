<template>
  <v-autocomplete
    class="select-unit-autocomplete py-0"
    :model-value="modelValue"
    :items="units"
    v-bind="$attrs"
    item-value="id"
    item-title="long_name"
    clear-on-select
  >
    <template #selection="{ item }">
      <v-list-item
        class="pa-0"
        :title="getTitle(item.raw)"
        :subtitle="!facilityId ? item.raw.facility?.name : null"
      >
        <template #append>
          <v-chip
            v-if="item.raw.rotation_types && item.raw.rotation_types.length"
            class="ml-2"
            size="small"
          >
            {{ formatParts(item.raw.rotation_types, ', ') }}
          </v-chip>
        </template>
      </v-list-item>
    </template>
    <template #item="{ props, item }">
      <v-list-item
        v-bind="props"
        :title="getTitle(item.raw)"
        :subtitle="!facilityId ? item.raw.facility.name : null"
      >
        <template #prepend="{ isSelected, select }">
          <v-checkbox-btn
            :model-value="isSelected"
            @update:model-value="select($event)"
          />
        </template>
        <template #append>
          <v-chip
            v-if="item.raw.rotation_types && item.raw.rotation_types.length"
            size="small"
          >
            {{ formatParts(item.raw.rotation_types, ', ') }}
          </v-chip>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [String, Object],
      default: null,
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      units: [],
    }
  },
  watch: {
    facilityId() {
      this.getData()
    },
  },
  async mounted() {
    this.getData()
  },
  methods: {
    getTitle(unit) {
      if (unit.short_name) {
        return unit.short_name + ' - ' + unit.long_name
      } else {
        return 'nicht gefunden'
      }
    },
    async getData() {
      let filterObject = {}
      if (this.facilityId) {
        filterObject = {
          filter: {
            facility: {
              _eq: this.facilityId,
            },
          },
        }
      }
      await this.$cms
        .request(
          this.$readItems('unit', {
            ...filterObject,
            fields: [
              'id',
              'short_name',
              'long_name',
              'rotation_types',
              'specialities.id',
              'specialities.speciality_id.id',
              'specialities.speciality_id.name',
              'type',
              'minimum_vacancies',
              'maximum_vacancies',
              'facility.id',
              'facility.name',
            ],
            sort: ['short_name'],
            limit: -1,
          })
        )
        .then((response) => {
          this.units = response.map((unit) => {
            return {
              ...unit,
              title: this.getTitle(unit),
            }
          })
        })
    },
  },
}
</script>
<style lang="scss">
.select-unit-autocomplete .v-field__input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
